import search from '../../assets/videos/Search.mp4';
import gold from '../../assets/img/gold.svg';
import silver from '../../assets/img/silver.svg';
import bronze from '../../assets/img/bronze.svg';
import { getDateInMonthDateYear, getTimeIn12HourFormat } from '../../constants/DateFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import contentCopy from '../../assets/img/contentCopy.svg';
import ActionDots from '../../assets/img/Action-Dots.svg';
import { Popover, Transition } from '@headlessui/react';
import { useState, Fragment } from 'react';
import Whatsapp from '../../assets/img/Whatsapp.svg';
import Edit from '../../assets/img/Edit.svg';
import Delete from '../../assets/img/Delete.svg';

function TextColumn({ row, columnKey }) {
  return <td className="pl-4 py-2.5 text-sm text-gray-750 whitespace-nowrap">{row[columnKey]}</td>;
}
function TextAndImage({ row, columnKey }) {
  if (row[columnKey]?.text)
    return (
      <td className="pl-4 py-2.5 text-sm text-gray-750">
        <img
          src={row[columnKey]?.image}
          alt=""
          className="w-8 h-8 rounded-full inline-block mr-3"
        />
        <span>{row[columnKey]?.text}</span>
      </td>
    );
  else return <td className="pl-14 py-2.5 text-xs text-gray-750">-</td>;
}

function ResultButton({ row, columnKey }) {
  if (row[columnKey]?.status === '') {
    return <td className="pl-4 py-2.5 text-xs text-gray-750">-</td>;
  }
  return (
    <td className="pl-4 py-2.5 text-xs text-gray-750">
      {row[columnKey]?.status !== 'Bronze' &&
      row[columnKey]?.status !== 'Silver' &&
      row[columnKey]?.status !== 'Gold' ? (
        <button
          className={`${
            row[columnKey]?.status === 'Qualified'
              ? 'bg-green-450 text-white'
              : 'bg-gray-300 text-gray-500'
          }  rounded-md w-28 px-4 py-1`}
        >
          {row[columnKey]?.status}
        </button>
      ) : (
        <button className="flex gap-2">
          <img
            src={
              row[columnKey].status === 'Gold'
                ? gold
                : row[columnKey].status === 'Silver'
                ? silver
                : bronze
            }
            width="25px"
            height="25px"
            alt="Medal"
          />
          <p>{row[columnKey]?.status}</p>
        </button>
      )}
    </td>
  );
}

function VideoColumn({ row, columnKey }) {
  return (
    <td className="pl-4 py-2.5 text-xs text-gray-750">
      <div className="text-sm text-gray-900 h-8 w-34 overflow-hidden rounded-md cursor-pointer">
        <video>
          <source src={row[columnKey] ? row[columnKey] : search} />
        </video>
      </div>
    </td>
  );
}
function OpponentColumn({ row, columnKey }) {
  if (row[columnKey]?.name)
    return (
      <td
        className={`pl-4 py-2.5 text-xs text-gray-750 ${row[columnKey]?.won ? 'bg-green-25' : ''}`}
      >
        <div className="flex">
          <img
            src={row[columnKey]?.image}
            alt="dp"
            className="w-8 h-8 rounded-full inline-block mr-3"
          />
          <div>
            <div className="flex gap-1">
              <span className="text-gray-750">{row[columnKey]?.name}</span>
              {/* <span className="text-gray-450">{row[columnKey]?.sfa_id}</span> */}
            </div>
            <span className="text-gray-650">{row[columnKey]?.institution}</span>
          </div>
        </div>
      </td>
    );
  else return <td className="pl-14 py-2.5 text-xs text-gray-750">-</td>;
}
function DateTimeColumn({ row, columnKey }) {
  return (
    <td className="pl-4 py-2.5 text-xs text-gray-750 flex flex-col">
      <span>{getDateInMonthDateYear(row[columnKey])}</span>
      <span className="text-gray-400">{getTimeIn12HourFormat(row[columnKey])}</span>
    </td>
  );
}

function ResultMedalButton({ row, columnKey }) {
  return (
    <td className="pl-4 py-2.5 text-xs text-gray-750">
      {row[columnKey]?.medal === 'gold' ? (
        <>
          <img src={gold} alt="gold" className="inline-block w-5 h-5 mr-2" />
          <span>Gold (1st)</span>
        </>
      ) : (
        ''
      )}
      {row[columnKey]?.medal === 'silver' ? (
        <>
          <img src={silver} alt="silver" className="inline-block w-5 h-5 mr-2" />
          <span>Silver (2nd)</span>
        </>
      ) : (
        ''
      )}
      {row[columnKey]?.medal === 'bronze' ? (
        <>
          <img src={bronze} alt="bronze" className="inline-block w-5 h-5 mr-2" />
          <span>Bronze (3rd)</span>
        </>
      ) : (
        ''
      )}
      {row[columnKey]?.medal === '' || row[columnKey]?.medal === 'participated' ? (
        <>
          <span>Participated</span>
        </>
      ) : (
        ''
      )}
    </td>
  );
}

function DeleteColumm({ row, columnKey }) {
  return (
    <td className="px-6 py-2.5 whitespace-nowrap">
      <FontAwesomeIcon
        title="Remove Player"
        icon={faTrash}
        className="cursor-pointer text-gray-450"
      ></FontAwesomeIcon>
    </td>
  );
}

function StatusColumn({ row, columnKey }) {
  return (
    <td className="px-6 py-2.5 whitespace-nowrap">
      <div className={`text-sm py-0.5 px-1.5 text-center rounded ${row[columnKey].colors}`}>
        {row[columnKey].status}
      </div>
    </td>
  );
}

function LinkColumn({ row, columnKey }) {
  return (
    <td className="px-2 lg:px-4 py-2.5 block-flex">
      <span className="overflow-hidden inline-flex w-52 text-ellipsis">{row[columnKey]}</span>
      <img
        src={contentCopy}
        alt="copy"
        className="ml-2 lg:ml-4 -mt-0.5 inline-block cursor-pointer"
        onClick={(e) => {
          e.target.style.transform = 'scale(0.8)';
          // e.target.parentElement.querySelector('span').innerText = 'Team Link Copied';
          navigator.clipboard.writeText(row[columnKey]);
          setTimeout(() => {
            e.target.style.transform = 'scale(1)';
            // e.target.parentElement.querySelector('span').innerText = row[columnKey];
          }, 500);
        }}
      />
    </td>
  );
}

function OptionsColumn({ row, columnKey }) {
  const [close, setClose] = useState(false);

  return (
    <div className="flex gap-2.5 md:gap-5 lg:gap-7 items-center">
      <Popover className="relative flex items-center">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
          ${open ? '' : 'text-opacity-90'}
          text-white group bg-orange-700 md:px-3 md:py-2 rounded-md inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <td
                className="px-4 py-2.5 block-flex justify-center items-center"
                onClick={
                  open
                    ? () => {
                        close ? setClose(false) : setClose(true);
                      }
                    : () => setClose(false)
                }
              >
                <img src={ActionDots} alt="option" className="block-block cursor-pointer" />
              </td>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className={`absolute z-10 w-48 -left-28 top-10 px-4 sm:px-0  ${
                  close ? 'hidden' : 'block'
                }`}
              >
                <div
                  className="overflow-hidden mr-9 mt-5 md:ml-0 md:mt-0 md:mr-0 rounded shadow-lg ring-1 ring-black ring-opacity-5"
                  onClick={() => setClose(true)}
                >
                  <div className="flex flex-col bg-white text-black py-1.5 border border-gray-250">
                    <ul>
                      <li
                        className="py-2 px-4 hover:bg-gray-100 cursor-pointer transition-colors duration-300"
                        // onClick = {() => {
                        //   history.push(row[columnKey].whatsapp_link);
                        // }
                        // }
                      >
                        <img src={Whatsapp} alt="" className="w-5 h-5 mr-2 inline-block" />
                        <span>Share on Whatsapp</span>
                      </li>
                      <li className="py-2 px-4 hover:bg-gray-100 cursor-pointer transition-colors duration-300">
                        <img src={Edit} alt="" className="w-5 h-5 mr-2 inline-block" />
                        <span>Manage Team</span>
                      </li>
                      <li className="py-2 px-4 hover:bg-gray-100 cursor-pointer transition-colors duration-300">
                        <img src={Delete} alt="" className="w-5 h-5 mr-2 inline-block" />
                        <span>Delete Team</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}

export default function Table({ columns, rows, data, fromTeamCreationCoach }) {
  return (
    <div
      className={`overflow-x-auto w-full mt-4 md:mt-0 max-w-full border-collapse border border-gray-200 bg-white ${
        !fromTeamCreationCoach ? 'pb-3 rounded-lg' : 'rounded-t-lg'
      }`}
    >
      <table className="divide-y divide-gray-200 tb text-left w-full font-roboto whitespace-nowrap">
        <thead className="bg-gray-150">
          <tr className="text-left border-b border-gray-300">
            {columns.map((column) => (
              <TableHeaderRow key={column.key} text={column.label} />
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.map((row, index) => {
            return (
              <tr key={index}>
                {columns.map((column, key) => {
                  // console.log(column, key);
                  return (
                    <TableRenderRow key={column.key} rowIndex={key} column={column} row={row} />
                  );
                })}{' '}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function TableHeaderRow({ text }) {
  return (
    <>
      <th className="block-flex items-center px-4 py-2.5">
        <span className="whitespace-nowrap text-gray-850 text-sm font-medium">{text}</span>
      </th>
    </>
  );
}

function TableRenderRow({ row, column }) {
  if (column.component === 'TextColumn') return <TextColumn row={row} columnKey={column.key} />;

  if (column.component === 'TextAndImage') return <TextAndImage row={row} columnKey={column.key} />;

  if (column.component === 'ResultButton') return <ResultButton row={row} columnKey={column.key} />;

  if (column.component === 'VideoColumn') return <VideoColumn row={row} columnKey={column.key} />;

  if (column.component === 'OpponentColumn')
    return <OpponentColumn row={row} columnKey={column.key} />;

  if (column.component === 'DateTimeColumn')
    return <DateTimeColumn row={row} columnKey={column.key} />;

  if (column.component === 'DateTimeColumn')
    return <DateTimeColumn row={row} columnKey={column.key} />;

  if (column.component === 'ResultMedalButton')
    return <ResultMedalButton row={row} columnKey={column.key} />;

  if (column.component === 'DeleteColumm') return <DeleteColumm row={row} columnKey={column.key} />;

  if (column.component === 'StatusColumn') return <StatusColumn row={row} columnKey={column.key} />;

  if (column.component === 'LinkColumn') return <LinkColumn row={row} columnKey={column.key} />;

  if (column.component === 'OptionsColumn')
    return <OptionsColumn row={row} columnKey={column.key} />;
}
