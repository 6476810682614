import React, { useState } from 'react';
import MainNav from '../Components/MainNav.js';
import AngleLeft from '../../assets/img/keyboard_arrow_left.svg';
import Done from '../../assets/img/Done.svg';
import Close from '../../assets/img/Close.svg';
import PlusOutline from '../../assets/img/Plus-Outline.svg';
import FiltersSectionBig from '../Components/FiltersSectionBig.js';
import Table from '../Components/Table.js';

export default function coachTeamCreation() {
  // const genders = ['Male', "Female", "Mixed"];
  // const ageGroups = ['U-11', 'U-17', 'U-20'];
  const [addTeamModal, setAddTeamModal] = useState(false);
  const columns = [
    {
      key: 'team_name',
      label: 'Team Name',
      component: 'TextColumn',
    },
    {
      key: 'accepted_by',
      label: 'Accepted By',
      component: 'TextColumn',
    },
    {
      key: 'created_at',
      label: 'Created At',
      component: 'TextColumn',
    },
    {
      key: 'link',
      label: 'Link',
      component: 'LinkColumn',
    },
    {
      key: 'options',
      label: '',
      component: 'OptionsColumn',
    },
  ];
  const rows = [
    {
      team_name: 'VangaBoyz7',
      accepted_by: '30%',
      created_at: 'INR 500',
      link: 'www.sfa.com/team-a-2903',
      options: {
        whatsapp_link: 'https://wa.me/917987654321',
      },
    },
    {
      team_name: 'Nobos Team',
      accepted_by: '21%',
      created_at: 'INR 350',
      link: 'www.sfa.com/team-b-2903',
      options: {
        whatsapp_link: 'https://wa.me/917987654321',
      },
    },
    {
      team_name: 'VangaDz7',
      accepted_by: '43%',
      created_at: 'INR 50',
      link: 'www.sfa.com/team-c-2903',
      options: {
        whatsapp_link: 'https://wa.me/917987654321',
      },
    },
  ];

  return (
    <div>
      <MainNav />

      <div className="relative flex flex-col gap-y-4 px-4 bg-gray-100 top-16 w-full md:w-11/12 font-roboto md:m-auto text-sm">
        <div className="bg-white w-full md:w-80 rounded md:px-4 md:py-8 leading-8 h-full md:mx-4 border border-gray-200">
          <FiltersSectionBig />
        </div>
        <div className="bg-white rounded  px-6 py-16 border border-gray-200 w-full md:w-8/12">
          <div className="flex justify-between">
            <div>
              <img
                src={AngleLeft}
                alt="back"
                className="inline-block -mt-1 -ml-1"
                width="20px"
                height="20px"
              />{' '}
              Sports Events List
            </div>
            <div className="text-gray-500">Basket Ball</div>
          </div>
          <div className="flex gap-2 md:gap-0 justify-between my-4">
            <h2 className="font-bold text-lg md:text-2xl">Create Team</h2>
            <h2 className="font-bold text-lg md:text-2xl">Knockout - Male - U19</h2>
          </div>
          <p className="shadow-paymentShadow text-gray-500 pt-4 my-4">
            You can add maximum of 5 team and Minimum 2 teams
          </p>

          {/* table starts here */}

          <Table rows={rows} columns={columns} fromTeamCreationCoach />

          <div
            className={`flex px-9 py-2 justify-between border border-gray-200 ${
              addTeamModal ? 'block' : 'hidden'
            }`}
          >
            <input
              type="text"
              className="border border-gray-300 w-40 rounded-lg py-2.5 pl-4 text-base"
              placeholder="Enter Team Name"
            />
            <div className="flex gap-4">
              <img
                src={Done}
                alt="done"
                className="-mt-1 cursor-pointer"
                width="24px"
                height="24px"
              />
              <img
                src={Close}
                alt="done"
                className="-mt-1 cursor-pointer"
                width="24px"
                height="24px"
                onClick={() => {
                  setAddTeamModal(false);
                }}
              />
            </div>
          </div>

          <div
            className="py-3 pl-9 text-base	rounded-b-lg border-l border-r border-b border-gray-200 hover:bg-blue-800 cursor-pointer transition-colors duration-300"
            onClick={() => {
              setAddTeamModal(true);
            }}
          >
            <img src={PlusOutline} alt="" className="inline-block -mt-1 mr-1 w-6 h-6" /> Add Team
          </div>
        </div>
      </div>
    </div>
  );
}
