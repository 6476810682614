import React from 'react';
import SelectList from 'components/select-list';

export default function FiltersSectionBig() {
  // const [minAge, setMinAge] = useState('Min Age')
  // const [maxAge, setMaxAge] = useState('Max Age')
  // const [sport, setSport] = useState('Select Sport')
  // const [filter, setFilter] = useState('Select Filter')

  const minAgeList = [
    '14 Years',
    '15 Years',
    '16 Years',
    '17 Years',
    '18 Years',
    '19 Years',
    '20 Years',
    '21 Years',
    '22 Years',
    '23 Years',
    '24 Years',
    '25 Years',
    '26 Years',
    '27 Years',
    '28 Years',
    '29 Years',
    '30 Years',
    '31 Years',
    '32 Years',
    '33 Years',
    '34 Years',
    '35 Years',
    '36 Years',
    '37 Years',
    '38 Years',
    '39 Years',
    '40 Years',
  ];
  const maxAgeList = [
    '14 Years',
    '15 Years',
    '16 Years',
    '17 Years',
    '18 Years',
    '19 Years',
    '20 Years',
    '21 Years',
    '22 Years',
    '23 Years',
    '24 Years',
    '25 Years',
    '26 Years',
    '27 Years',
    '28 Years',
    '29 Years',
    '30 Years',
    '31 Years',
    '32 Years',
    '33 Years',
    '34 Years',
    '35 Years',
    '36 Years',
    '37 Years',
    '38 Years',
    '39 Years',
    '40 Years',
  ];

  const sportList = [
    'Cricket',
    'Football',
    'Tennis',
    'Basketball',
    'Volleyball',
    'Hockey',
    'Baseball',
    'Swimming',
    'Gymnastics',
    'Athletics',
    'Rugby',
    'Boxing',
    'Wrestling',
    'Diving',
    'Golf',
    'Cycling',
    'Rowing',
    'Running',
  ];

  return (
    <div>
      <div className="bg-white px-4 py-8 rounded-lg">
        <p className="text-xl font-bold text-gray-750">Filters</p>
        <p className="text-gray-750 text-sm mt-4">Gender</p>
        <div className="flex items-center mt-2.5">
          <input
            type="radio"
            name="gender"
            id=""
            className="form-radio text-gray-550 border-2 border-gray-550 rounded-full mr-2.5 w-5 h-5"
          />
          <label htmlFor="" className="text-sm text-gray-550">
            Male
          </label>
          <input
            type="radio"
            name="gender"
            id=""
            className="form-radio text-gray-550 border-2 border-gray-550 rounded-full mr-2.5 ml-4 w-5 h-5"
          />
          <label htmlFor="" className="text-sm text-gray-550">
            Female
          </label>
          <input
            type="radio"
            name="gender"
            id=""
            className="form-radio text-gray-550 border-2 border-gray-550 rounded-full mr-2.5 ml-4 w-5 h-5"
          />
          <label htmlFor="" className="text-sm text-gray-550">
            Others
          </label>
        </div>
        <p className="text-gray-750 text-sm mt-8">Age Category</p>
        <div className="flex gap-4 mt-2 pb-4 shadow-shadowBottom">
          {/* Min Age */}
          <SelectList
            selectedValue=""
            setSelectedValue="setSelectedValue"
            placeholder="Min Age"
            listData={minAgeList}
          />
          {/* Max Age */}
          <SelectList
            selectedValue=""
            setSelectedValue="setSelectedValue"
            placeholder="Max Age"
            listData={maxAgeList}
          />
        </div>
        <p className="text-gray-750 text-sm mt-4">Registration Dates</p>
        <div className="flex gap-4 mt-2 pb-4 shadow-shadowBottom">
          {/* Sport */}
          <SelectList
            selectedValue=""
            setSelectedValue="setSelectedValue"
            placeholder="Start Date"
            listData={sportList}
          />
          <SelectList
            selectedValue=""
            setSelectedValue="setSelectedValue"
            placeholder="End Date"
            listData={sportList}
          />
        </div>
        <p className="text-gray-750 text-sm mt-4">Sport</p>
        <div className="mt-2 pb-4 shadow-shadowBottom">
          <SelectList
            selectedValue=""
            setSelectedValue="setSelectedValue"
            placeholder="Select Sport"
            listData={sportList}
          />
        </div>
        <p className="text-gray-750 text-sm mt-4">Sport Catagory</p>
        <div className="mt-2 pb-4">
          <SelectList
            selectedValue=""
            setSelectedValue="setSelectedValue"
            placeholder="Select Sport Catagory"
            listData={sportList}
          />
        </div>
      </div>
    </div>
  );
}
